import { useMemo, Children, useEffect } from 'react';
import CacheBuster from 'react-cache-buster';
import { createPortal } from "react-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Modal from './components/Modal';
import { PopperContainer } from './components/Popper';
import Sidebar from './layout/Sidebar';
import Titlebar from './layout/Titlebar';
import LazySafePage from './pages/LazySafePage';
import NotFound from './pages/NotFound';
import useGui from './utils/gui';
import { useChannel, useSocketcluster } from './utils/socketcluster';
import Login from './Login';
import './utils/logoutWarning';

import { version } from '../package.json';

import './utils/toast';
import { NestedForms } from './components/NestedForms';

export function AppSkeleton({children: rawChildren})
{
  const children = Children.toArray(rawChildren);

  return <>
    {children[0]}
    <div className="flex flex-col flex-grow">
      {children[1]}
      <div className="p-4 xl:p-8 transition-all h-full min-h-0">
        {children.slice(2)}
      </div>
    </div>
  </>;
}

function LoggedInContent({authToken})
{
  useSocketcluster();

  const location = useLocation();

  useChannel(`/limits/${authToken?.cid}`, useGui.getState().limits[1]);

  if ((authToken.per.has('setup-2fa') || authToken.per.has('change-password')) && location.pathname !== '/me') return <Redirect to="/me" />;

  return <AppSkeleton>

    <Sidebar />

    <Titlebar />

    <Switch>
      <Route path="/" exact><Redirect to="/connections" /></Route>
      <Route path={['/connections/:selectedCode/:subNav', '/connections/:selectedCode', '/connections']}><LazySafePage page="Connections" /></Route>
      <Route path="/matrix"><LazySafePage page="Matrix" /></Route>
      <Route path="/router/qr/:referenceId"><LazySafePage page="RouterQr" /></Route>
      <Route path="/router"><LazySafePage page="Router" /></Route>
      <Route path={['/cloud-connect/:referenceId/:streamId+', '/cloud-connect/:referenceId', '/cloud-connect']}><LazySafePage page="CloudConnect" /></Route>
      <Route path={['/presenter-control/:connectionId', '/presenter-control']}><LazySafePage page="PresenterControl" /></Route>
      <Route path="/encoders"><LazySafePage page="Encoders" /></Route>
      <Route path="/claim"><LazySafePage page="Claim" /></Route>
      {authToken.per.has('company') && <Route path="/company"><LazySafePage page="Company" /></Route>}
      {authToken.per.has('admin') && <Route path="/admin"><LazySafePage page="Admin" /></Route>}
      {authToken.per.has('doc') && <Route path={['/documentation/:method/:path(.*)', '/documentation']}><LazySafePage page="Documentation" /></Route>}
      {authToken.per.has('admin') && authToken.per.has('dev') && authToken.per.has('doc') && <Route path="/validation-test"><LazySafePage page="ValidationTest" /></Route>}
      <Route path={['/downloads/:appSlug/:param', '/downloads/:appSlug', '/downloads']}><LazySafePage page="Downloads" /></Route>
      <Route path="/shop"><LazySafePage page="Shop" /></Route>

      <Route path="/me"><LazySafePage page="MyProfile" /></Route>
      <Route path="/settings"><LazySafePage page="Settings" /></Route>

      <Route path="/sign-up"><Redirect to="/welcome" /></Route>
      <Route path="/welcome"><LazySafePage page="Welcome" /></Route>

      <Route component={NotFound} />
    </Switch>

  </AppSkeleton>;
}

function AnonymousContent({_2fa})
{
  const showRoutes = !_2fa;

  return <AppSkeleton>

    <Sidebar />

    <></>

    <Switch>
      {showRoutes && <Route path={['/cloud-connect/:referenceId/:streamId+', '/cloud-connect/:referenceId']}><LazySafePage page="CloudConnectAnonymous" /></Route>}
      {showRoutes && <Route path={['/downloads/:appSlug/:param', '/downloads/:appSlug', '/downloads']}><LazySafePage page="Downloads" /></Route>}
      {showRoutes && <Route path="/sign-up"><LazySafePage page="Signup" /></Route>}
      {showRoutes && <Route path="/request-password-reset"><LazySafePage page="RequestPasswordReset" /></Route>}
      {showRoutes && <Route path="/reset-password/:token64"><LazySafePage page="ResetPassword" /></Route>}

      <Route component={Login} />
    </Switch>
  </AppSkeleton>;
}

const isProduction = process.env.NODE_ENV === 'production';
export default function App()
{
  const [authToken] = useGui(s => s.authToken);
  const [globalMessage] = useGui(s => s.globalMessage);

  const toastPortal = useMemo(() => document.getElementById('toast-portal'), []);

  return <CacheBuster currentVersion={version} isEnabled={isProduction}>
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${process.env.REACT_APP_APP_NAME}`} defaultTitle={process.env.REACT_APP_APP_NAME}>
        {authToken?.sbt && <script type="text/javascript" id="zsiqchat">{`var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "33ca9c4a6409b59d674851316118154697207d115a8e97cc5f1c016dd620d9a2", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`}</script>}
      </Helmet>
      {authToken?.sbt && <UnloadSupportBot />}
      <Router>
        <Switch>
          <Route path="/endpoint-integration/"><LazySafePage page="EndpointIntegration" {...{authToken}} /></Route>
          <Route>
            {authToken && authToken.id && !authToken.per.has('2fa') ? <LoggedInContent authToken={authToken} /> : <AnonymousContent _2fa={authToken?.per.has('2fa') } />}
          </Route>
        </Switch>
        <PopperContainer />
      </Router>
      <NestedForms />
      <Modal isOpen={!!globalMessage} className="top-1/3 p-4 w-auto">{globalMessage}</Modal>
      <>{createPortal(<ToastContainer
        toastClassName={() => 'bg-shade-700 shadow relative flex p-1 mb-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'}
        bodyClassName="text-sm text-white"
        closeButton={false}
        limit={5}
        hideProgressBar={true}
        />, toastPortal)}</>
    </HelmetProvider>
  </CacheBuster>;
}

function UnloadSupportBot()
{
  useEffect(() => () => window.location.reload(), []);
  return null;
}
