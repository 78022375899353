import produce from "immer";
import create from "zustand";
import { useEffect, useState, useCallback, useMemo, useReducer } from "react";
import { onFetch, resToJson } from "./data";
import socketClusterClient from 'socketcluster-client';
import { unstable_batchedUpdates } from "react-dom";
import useGui, { sessionChannel } from "./gui";
import { DateTime } from "luxon";

import { version as currentVersion } from '../../package.json';
import { toast } from "react-toastify";

// let setImmediate;
let setOutside;

const useSc = create(function(rawSet)
{
  const set = fn => rawSet(produce(fn));
  // setImmediate = set;
  setOutside = fn => unstable_batchedUpdates(() => set(fn));

  return {
    socket: null,
    connected: false,
    error: null,
  };
});
export default useSc;

const wsEvents = {
  error({error})
  {
    console.log(error.name, error.message);
    setOutside(s => { s.error = error; s.socketState = this.state; });
  },
  connect()
  {
    console.log('connect');
    setOutside(s => { s.connected = true; s.error = null; s.socketState = this.state; });
  },
  close({code, reason})
  {
    console.log('close', code, reason);
    setOutside(s => { s.connected = false; s.socketState = this.state; });
  },
  connecting()
  {
    console.log('connecting');
    setOutside(s => { s.socketState = this.state; });
  },
  authenticate()
  {
    console.log('authenticate');
    setOutside(s => { s.socket = this; s.socketState = this.state; });
  },
  deauthenticate()
  {
    console.log('deauthenticate');
  },
};

export function useSocketcluster(authTokenStr)
{
  useEffect(() =>
  {
    const socket = socketClusterClient.create({
      autoConnect: false,
      authEngine: {
        removeToken: () => Promise.resolve(),
        saveToken: () => Promise.resolve(),
        loadToken: () => authTokenStr || window.fetch('/api/load-token').then(resToJson).then(res =>
        {
          sessionChannel?.postMessage({msg: 'login'});
          return res;
        }).catch(console.log),
      },
      protocolVersion: 1,
    });
    onFetch.fn = () =>
    {
      if (socket.state !== 'open') socket.connect();
    }

    const channelReferences = new Map();
    socket.subscribeManaged = function(channelName, options)
    {
      const ref = channelReferences.get(channelName);
      if (ref)
      {
        ++ref.count;
        window.clearTimeout(ref.timeout);
      }
      else
      {
        channelReferences.set(channelName, {count: 1});
        this.closeChannel(channelName);
      }

      return this.subscribe(channelName, options);
    };
    socket.unsubscribeManaged = function(channelName)
    {
      const ref = channelReferences.get(channelName);
      --ref.count;
      if (ref.count <= 0)
      {
        ref.timeout = window.setTimeout(() =>
        {
          channelReferences.delete(channelName);
          this.unsubscribe(channelName);
        }, 1000);
      }
    };

    Object.entries(wsEvents).forEach(async ([type, listener]) =>
    {
      for await (const data of socket.listener(type))
      {
        try
        {
          listener.call(socket, data);
        }
        catch (e)
        {
          console.log('error handling event', type, e);
        }
      }
    });

    async function receiver(receiver, handle)
    {
      for await (const data of socket.receiver(receiver)) handle(data);
    }

    receiver('guiVersion', version => {
      if (version !== currentVersion && version !== '3.1.0') toast.info(<>
        <div className="leading-tight">A new version of <span className="text-primary">{process.env.REACT_APP_APP_NAME}</span> is available.</div>
        <div className="leading-tight">Please reload the page at your earliest convenience to ensure smooth operation of the service.</div>
        <div className="text-xs">Click or swipe this notification to reload</div>
      </>, {
        autoClose: false,
        toastId: 'gui-update-available',
        onClick: () => window.location.reload(),
      });
    });
    receiver('subscriptionInfo', ({types}) => useGui.getState().subscriptionTypes[1](new Set(types)));

    socket.connect();

    return () =>
    {
      setOutside(s => { s.socket = null; });
      socket.disconnect();
    }
  }, [authTokenStr]);
}

function getChannelHandlers(channelName)
{
  return {
    init(data)
    {
      setOutside(s => { s[channelName] = data; });
    },
    add(item)
    {
      setOutside(s =>
      {
        const arr = s[channelName];
        if (!arr) return;
        const index = arr.findIndex(el => el.id === item.id);
        if (index === -1) arr.push(item);
        else arr[index] = item;
      });
    },
    update({id, data})
    {
      setOutside(s =>
      {
        const arr = s[channelName];
        if (!arr) return;
        const index = arr.findIndex(el => el.id === id);
        if (index === -1) return;
        Object.assign(arr[index], data);
      });
    },
    delete(id)
    {
      setOutside(s =>
      {
        const arr = s[channelName];
        if (!arr) return;
        const index = arr.findIndex(el => el.id === id);
        if (index !== -1) arr.splice(index, 1);
      });
    }
  };
}

function useChannelInternal(channelName, onData, subscribe, unsubscribe)
{
  const socket = useSc(s => s.socket);

  useEffect(() =>
  {
    try
    {
      if (!socket || !channelName) return;

      const channel = socket[subscribe](channelName);
      let done;
      (async () =>
      {
        for await (const data of channel)
        {
          if (done) break;
          onData(data);
        }
      })();

      return () =>
      {
        done = true;
        return socket[unsubscribe](channelName);
      }
    }
    catch (e)
    {
      console.log(e);
    }
  }, [socket, channelName, onData, subscribe, unsubscribe]);
}

function useChannelManaged(channelName, onData)
{
  useChannelInternal(channelName, onData, 'subscribeManaged', 'unsubscribeManaged');
}

export function useChannel(channelName, onData)
{
  useChannelInternal(channelName, onData, 'subscribe', 'unsubscribe');
}

const uppercaseRegex = /[A-Z]/g;
const camelToKebab = camel => camel.replace(uppercaseRegex, '-$&').toLowerCase();
export function useDataChannel(channelName)
{
  useChannelManaged(
    useMemo(() => channelName.endsWith('Global') ? `/${camelToKebab(channelName)}` : `/${camelToKebab(channelName)}/${useGui.getState().authToken[0]?.cid}`, [channelName]),
    useMemo(() =>
    {
      const handlers = getChannelHandlers(channelName);
      return packet =>
      {
        const handler = handlers[packet.msg];
        if (!handler) return console.log(`received unknown message ${packet.msg} on channel ${channelName}`);
        handler(packet.data);
      };
    }, [channelName]));
}

export function useChannelLatest(channelName, initialData)
{
  const [data, setData] = useState(initialData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setData(initialData); }, [channelName]);
  useChannel(channelName, setData);
  return data;
}

export function useChannelLatestManaged(channelName, initialData)
{
  const [data, setData] = useState(initialData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setData(initialData); }, [channelName]);
  useChannelManaged(channelName, setData);
  return data;
}

export function useChannelLast2(channelName, initialData)
{
  const [data, onData] = useReducer((s, data) => ({current: data, last: s.current}), {current: initialData});
  useChannel(channelName, onData);
  return data;
}

export function useChannelLast2Managed(channelName, initialData)
{
  const [data, onData] = useReducer((s, data) => ({current: data, last: s.current}), {current: initialData});
  useChannelManaged(channelName, onData);
  return data;
}

export function useChannelAccumulate(channelName, initialData)
{
  const [data, setData] = useState(initialData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setData(initialData); }, [channelName]);
  useChannel(channelName, useCallback(d => setData(s => ({...s, ...d})), []));
  return data;
}

export function useChannelAccumulateManaged(channelName, initialData)
{
  const [data, setData] = useState(initialData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { setData(initialData); }, [channelName]);
  useChannelManaged(channelName, useCallback(d => setData(s => ({...s, ...d})), []));
  return data;
}

const clearLogSymbol = Symbol('clear log');
let lastLogKey = 0;
export function useChannelLog(channelName)
{
  const [data, onData] = useReducer((s, data) =>
  {
    if (data === clearLogSymbol) return [];
    data._key = ++lastLogKey;
    data._t = DateTime.now();
    return s.concat([data]);
  }, []);
  const clear = useCallback(() => onData(clearLogSymbol), [onData]);
  useChannel(channelName, onData);
  return [data, clear];
}
